<script lang="ts">
	import axios from 'axios';
	import Cookies from 'js-cookie';
	import { onMount } from 'svelte';

	import { COOKIE_KEYS } from '~/utils/cookie';
	import Input from './Input.svelte';
	import { Label } from 'shadcn/label';
	import { Button } from 'shadcn/button';
	import Logo from './AutoSettleLogo.svelte';

	let redirectUrl = '';

	let value = '';
	let error = '';
	let isLoading = false;

	const onClick = async () => {
		if (!value) return;

		error = '';
		isLoading = true;

		const fd = new FormData();

		fd.set('password', value);

		try {
			await axios.post('/api/check-pwd', fd, {
				baseURL: window.location.origin,
			});

			Cookies.set(COOKIE_KEYS.pwdp, window.btoa(value));

			window.location.replace(redirectUrl);
		} catch (err) {
			error = 'Incorrect password';
		}

		isLoading = false;
	};

	onMount(() => {
		const pwdp = Cookies.get(COOKIE_KEYS.pwdp);

		if (pwdp) {
			const sp = new URLSearchParams(window.location.search);

			redirectUrl = decodeURI(atob(sp.get('cb') || '')) || '';

			if (!redirectUrl) return;

			window.location.replace(redirectUrl);
		}
	});
</script>

<div class="flex items-center justify-center min-w-screen min-h-screen bg-dark">
	<div class="max-w-[340px] w-full p-s3 bg-darker rounded-s2 flex flex-col gap-s2">
		<div>
			<Logo aria-hidden />
		</div>

		<hr />

		<div class="flex flex-col gap-s1 text-sm leading-4">
			<p>Thanks for being part of the AutoSettle Alpha Release Feedback Group.</p>

			<p>Please enter the password you received in your welcome email to continue.</p>
		</div>

		<hr />

		<div class="flex flex-col gap-s1">
			<Label class="text-white text-sm font-semi-bold">Password</Label>
			<Input type="password" bind:value disabled={isLoading} />
			<span class="text-xs text-danger">{error}</span>
			<Button disabled={isLoading} on:click={onClick}>Confirm</Button>
		</div>
	</div>
</div>
